import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/privacy',
    name: 'privacy',
    meta: { title: '隐私政策' },
    component: () => import('../views/privacy.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: { title: '用户协议' },
    component: () => import('../views/user.vue')
  },
  {
    path: '/children',
    name: 'children',
    meta: { title: '儿童个人信息保护指引' },
    component: () => import('../views/children.vue')
  },
  {
    path: '/cancellation_agreement',
    name: 'cancellation',
    meta: { title: '注销协议' },
    component: () => import('../views/cancellation.vue')
  },
  {
    path: '/SDKintro',
    name: 'SDKintro',
    meta: { title: '第三方SDK类服务商目录' },
    component: () => import('../views/sdk.vue')
  },
  {
    path: '/app_privacy',
    name: 'app_privacy',
    meta: { title: '隐私政策' },
    component: () => import('../views/app_privacy.vue')
  },
  {
    path: '/app_user',
    name: 'app_user',
    meta: { title: '用户协议' },
    component: () => import('../views/app_user.vue')
  },
  {
    path: '/power',
    name: 'power',
    meta: { title: '权限说明' },
    component: () => import('../views/power.vue')
  },
  {
    path: '/power1',
    name: 'power',
    meta: { title: '权限说明' },
    component: () => import('../views/power1.vue')
  },
  {
    path:'/privacy1',
    name: 'privacy1',
    meta: { title: '隐私政策' },
    component: () => import('../views/privacy1.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title
  }
  // else {
  //   document.title = ''
  // }
  next() //执行进入路由，如果不写就不会进入目标页
})

export default router
